/* Global Styles */
:root {
  --primary-color: #80c906;
  --secondary-color: #b8e211;
  --dark-color: #1a1919;
  --light-color: #ffffff;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.container {
  max-width: 2500px;
  margin: auto;
}

.container-content {
  max-width: 1440px;
  margin: auto;
  padding: 1.5rem;
}

.intro {
  text-align: center;
}

a {
  color: var(--light-color);
  text-decoration: none;
}

h1 {
  text-align: center;
}

ul {
  list-style: none;
}

.btn {
  padding: 0.4rem 1rem;
}

.btn-ghost {
  background-color: transparent;
  border: solid 2px var(--light-color);
  color: white;
  margin: 1.5rem 0;
  font-weight: bold;
  height: 60px;
  font-size: 18px;
  transition: 0.2s ease-in-out;
}

.btn-ghost:hover {
  transform: scale(1.1);
}

/* Forms */
input,
textarea {
  margin: 0.7rem 0;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="tel"],
select,
textarea {
  display: block;
  width: 180px;
  height: 33.6px;
  padding: 0.4rem;
  border: 2px solid white;
  background-color: transparent;
  color: white;
}

input[type="submit"],
button {
  font: inherit;
}

button[type="submit"]:disabled {
  background-color: #cccccc;
  color: #666666;
}

label {
  font-weight: bold;
  font-size: 14px;
}

/* Navbar */
.navbar {
  display: none;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding: 1.5rem;
  top: 0;
  background-color: var(--dark-color);
}

.navbar .logo {
  width: 152px;
  height: 43px;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: var(--light-color);
  padding: 0.7rem 0.5rem;
}

.navbar h1 {
  color: var(--primary-color);
  font-weight: bold;
}

.navbar a:hover {
  color: #e67300;
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* FORM */

textarea {
  width: 222px;
  height: 132px;
}

/* Footer */
footer {
  background-color: var(--dark-color);
  text-align: center;
}

footer a.cofinanc {
  display: block;
}

footer a.cofinanc img {
  width: 400px;
  margin-top: 1.5rem;
}

footer p {
  font-size: 10px;
  margin-top: 0.5rem;
  color: var(--light-color);
}

footer img {
  width: 30.1px;
  cursor: pointer;
}

.sliderWrapper {
  width: 100%;
}

.slick-slide img {
  height: 213px;
  width: 100%;
  object-fit: cover;
}
/* Bounce Animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
/* Mobile Styles */
@media (max-width: 700px) {
  form {
    align-items: center;
  }

  footer a.cofinanc img {
    width: 200px;
    margin-top: 0.5rem;
  }
  /* Individual item */
  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  /* Change color on hover */
  .bm-item:hover {
    color: white;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 26px;
    top: 30px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: var(--light-color);
    height: 15% !important;
    border-radius: 3px;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: var(--dark-color);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  .bm-menu-wrap {
    top: 0;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}
