/* Font */
@font-face {
  font-family: FuturaPT-light;
  src: url(dist/fonts/FuturaPTLight.otf);
}
@font-face {
  font-family: FuturaPT-bold;
  src: url(dist/fonts/FuturaPTBold.otf);
}
@font-face {
  font-family: FuturaPT-medium;
  src: url(dist/fonts/FuturaPTMedium.otf);
}

@font-face {
  font-family: FuturaPT-book;
  src: url(dist/fonts/FuturaPTBook.otf);
}

/* Geral */
* {
  font-family: FuturaPT-book;
}

h1 {
  font-family: FuturaPT-bold;
  color: var(--dark-color);
  font-size: 2rem;
}

p {
  color: var(--dark-color);
  font-size: 1.2rem;
  text-align: center;
}

a {
  cursor: pointer;
  font-size: 1.3rem;
}

hr {
  width: 2rem;
  margin: 10px auto;
}

hr.primary {
  border: 1.5px solid var(--primary-color);
}

hr.light {
  border: 1.5px solid var(--light-color);
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Home */

.section-home img {
  width: 100%;
}

.section-home > div {
  display: none;
}

/* History */
.history-slide img {
  height: 142px;
  object-fit: cover;
}

.history-slide p {
  padding: 0 1.5rem;
  font-size: 13px;
}

/* Services */

.section-services > img {
  width: 100vw;
}

.section-services h1 {
  color: white;
}

.section-services > .intro > img {
  width: 90px;
}

.section-services > .details {
  padding: 0.8rem 0;
  padding-bottom: 1.2rem;
}

.section-services > .details > .btn {
  width: 133px;
  height: 27px;
  font-size: 12px;
  line-height: 1.9;
}

/* Localization */

.section-localization > .intro {
  padding: 1.5rem;
}

.section-localization > iframe {
  width: 100%;
  height: 331px;
}

/* Contacts */

.section-contacts {
  background-color: var(--dark-color);
  color: var(--light-color);
}

.section-contacts h1,
.section-contacts p {
  color: var(--light-color);
}

.section-contacts form {
  margin-top: 1.5rem;
}

.section-contacts form p {
  text-align: center;
}

.submit {
  width: 100px;
  height: 40px;
  font-size: 14px;
  line-height: initial;
}

.BrainhubCarousel
  > .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft,
.BrainhubCarousel
  > .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight {
  display: none;
}

/* Desktop Styles */
@media (min-width: 700px) {
  .navbar {
    display: flex;
  }
  /* Geral */

  img {
    margin: 0;
  }

  h1 {
    font-size: 50px;
  }

  h3 {
    font-size: 29px;
  }

  p {
    font-size: 25px;
    text-align: left;
  }

  label {
    font-size: 22px;
  }

  .container-content {
    width: calc(100% - 8.6rem);
    padding: 2.5rem;
  }

  .content-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
  }

  .content-column {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
  }

  /* History */

  .history-slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 70px;
  }

  .history-slide > img {
    width: 392px;
    height: auto;
  }

  .history-slide p {
    padding: 2rem 2.5rem;
    font-size: 25px;
  }
  /* Home */

  .section-home {
    max-height: 1067px;
  }

  .section-home img {
    width: 100%;
    height: fit-content;
    padding-top: 91px;
    object-fit: cover;
  }

  .section-home > div {
    display: block;
    text-align: center;
    position: relative;
    bottom: 140px;
  }
  .section-home > div > a {
    background: none;
    border: none;
    cursor: pointer;
  }

  .section-home > div > a:focus {
    outline: 0;
  }

  .section-home > div > a > i {
    font-size: 50px;
    color: white;
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
  }

  /* Ementas */

  .section-services > img {
    width: 50%;
    height: 712px;
    object-fit: cover;
  }

  .section-services > div > div > .intro {
    height: 712px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .section-services > .intro > .btn {
    width: 307px;
    height: 71px;
    font-size: 26px;
    line-height: 1.9;
  }

  .section-services > .intro > img {
    width: 3rem;
    margin: 1.5rem;
  }

  .sliderWrapper {
    width: 50%;
  }

  .slick-slide img {
    height: 712px;
    width: 100%;
    object-fit: cover;
  }

  /* Localization */
  .section-localization {
    display: flex;
    justify-content: center;
  }

  .section-localization > .intro {
    display: inherit;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 712px;
  }

  .section-localization > .intro > .container {
    text-align: center;
  }

  .section-localization > iframe {
    width: 50%;
    height: 712px;
  }
  /* Contacts */
  .section-contacts .row {
    display: flex;
    justify-content: space-evenly;
  }

  .section-contacts .row .input-group {
    text-align: left;
    margin-top: 1.5rem;
  }

  .section-contacts .row .input-group input {
    width: 345px;
    height: 70px;
    border: solid 3px var(--light-color);
    padding: 0 1.3rem;
    font-size: 18px;
  }

  .section-contacts .input-group textarea {
    width: 911px;
    height: 229px;
    border: solid 3px var(--light-color);
    padding: 1.3rem;
    font-size: 18px;
  }

  .section-contacts > .container > form {
    align-items: normal;
  }

  .section-contacts > .container > form > button {
    width: 271px;
    height: 71px;
  }
  .section-contacts > .container > form > .row-submit {
    flex-direction: column;
    align-items: center;
  }

  .submit {
    width: 271px;
    height: 71px;
    font-size: 26px;
  }

  .BrainhubCarousel
    > .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft,
  .BrainhubCarousel
    > .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight {
    display: block;
    cursor: pointer;
  }

  .BrainhubCarousel
    > .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft {
    margin-right: 2.5rem;
  }

  .BrainhubCarousel
    > .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight {
    margin-left: 2.5rem;
  }

  .BrainhubCarousel
    > .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft
    > i,
  .BrainhubCarousel
    > .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight
    > i {
    font-size: 50px;
  }
  .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    width: 10px;
    height: 10px;
  }

  footer img {
    width: 49.9px;
  }

  footer p {
    font-size: 12px;
    letter-spacing: 2.67px;
    padding: 1.5rem 0;
  }
}
